import React, { useEffect, useState } from "react";
import axios from "axios";
import { graphql } from "gatsby";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Router } from "@reach/router";
import Layout from "../../components/layout/layout";
import { urls } from "../../config";
import "./reset-password.scss";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const ResetPasswordForm = ({ path, resetToken, ...props }) => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [buttonDisabledStatus, setButtonDisabledStatus] = useState(true);
  const { t, language } = useTranslation();
  const { languages, changeLanguage } = useI18next();
  const strings = {
    error: "An unknown error occurred. Please try again later.",
    submit: "Update",
  };

  useEffect(() => {
    if (!resetToken) {
      setErrorMessage("Reset token not found.");
    }
  }, []);

  useEffect(() => {
    if (password && confirmPassword) {
      if (password === confirmPassword) {
        setButtonDisabledStatus(false);
        setErrorMessage("");
      } else {
        setErrorMessage(t("passwordsDoNotMatch"));
        setButtonDisabledStatus(true);
      }
    }
  }, [password, confirmPassword]);

  const resetPasswordSubmitHandler = async (e) => {
    e.preventDefault();
    setPending(true);
    setErrorMessage("");
    try {
      const response = await axios({
        method: "POST",
        url: `${urls.resetPassword}`,
        data: { password, token: resetToken },
      });
      const { result, error } = response.data;
      if (result) {
        setSubmitted(true);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data.error &&
        err.response.data.error === "token not found"
      ) {
        setErrorMessage(t("invalidToken"));
      } else {
        setErrorMessage(t("oopsError"));
      }
    }
    setPending(false);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="form-login">
      {submitted && (
        <p className={"message success"}>{t("resetToNewPasswordSuccess")}</p>
      )}
      {!submitted && (
        <>
          <div className="input-wrap">
            <input
              type="password"
              name="password"
              placeholder={t("password")}
              onChange={onPasswordChange}
            />
          </div>
          <div className="input-wrap">
            <input
              type="password"
              name="confirm-password"
              placeholder={t("confirmPassword")}
              onChange={onConfirmPasswordChange}
            />
          </div>
          {errorMessage && (
            <div className="input-wrap">
              <p className={"message error"}>{errorMessage}</p>
            </div>
          )}
          <div className="input-wrap">
            <button
              type="button"
              id="submitButton"
              onClick={resetPasswordSubmitHandler}
              disabled={buttonDisabledStatus}
            >
              {t("resetPassword")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const ResetPassword = ({ data: { ribbon, header, footer } }) => {
  const { t, language } = useTranslation();
  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="no-banner"
    >
      <section className="forgot-page">
        <div className="login-form-container">
          <h1>{t("resetYourPasswordText")}</h1>
          <Router>
            <ResetPasswordForm path="/reset-password" />
            <ResetPasswordForm path="/reset-password/:resetToken" />
            <ResetPasswordForm path="/es/reset-password" />
            <ResetPasswordForm path="/es/reset-password/:resetToken" />
          </Router>
        </div>
      </section>
    </Layout>
  );
};

export default ResetPassword;
